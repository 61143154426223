<template>
  <div>
    <b-button-group class="mb-1">
      <b-button
        :variant="!+lang ? 'primary' : 'outline-primary'"
        to="/admin/service-setting/0"
        >عربي</b-button
      >
      <b-button
        to="/admin/service-setting/1"
        :variant="!+lang ? 'outline-primary' : 'primary'"
        >English</b-button
      >
    </b-button-group>
    <b-row>
      <b-col cols="12" lg="6">
        <h4>رابط الفيديو</h4>
        <ValidationObserver ref="observer">
          <b-form>
            <b-input-group>
              <template #append>
                <b-button
                  variant="success"
                  class="p-50"
                  @click="VideoCode ? submitVideo() : () => {}"
                >
                  <unicon name="check" width="22" height="22" fill="#fff" />
                </b-button>
              </template>
              <b-form-input
                ref="videoInput"
                :value="settings.videoUrl"
                @input="setVideo"
                placeholder="ادخل شيفرة تضمين الفيديو"
              />
            </b-input-group>
            <small class="text-danger" v-show="!VideoCode"
              >شيفرة التضمين مطلوبة</small
            >
          </b-form>
        </ValidationObserver>

        <div class="video-viewer mt-1">
          <div
            v-html="!VideoCode ? settings.videoUrl : VideoCode"
          ></div>
        </div>
      </b-col>
      <b-col cols="12" lg="6">
        <h4>عنوان قسم مراحل الغزل</h4>
         <ValidationObserver ref="observer">
          <b-form>
            <b-input-group>
              <template #append>
                <b-button
                  variant="success"
                  class="p-50"
                  @click="servicesPageDto.title ? submitSetServiceSettings() : () => {}"
                >
                  <unicon name="check" width="22" height="22" fill="#fff" />
                </b-button>
              </template>
              <b-form-input
                ref="videoInput"
                v-model="servicesPageDto.title"
                placeholder="ادخل عنوان صفحة الخدمات"
              />
            </b-input-group>
            <small class="text-danger" v-show="!servicesPageDto.title"
              >عنوان صفحة الخدمات مطلوب</small
            >
          </b-form>
        </ValidationObserver>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
            v-for="(stage, index) in activeStagesList"
            :key="index"
          >
            <createServiceCard :item="stage" />
          </b-col>
          <b-col cols="12" class="d-flex justify-content-center">
            <b-pagination
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              :value="stagesFirstPage"
              :total-rows="servicesPageDto.stages.length"
              :per-page="stagesPageLength"
              @input="(value) => updateStagesPagination(value)"
            >
              <template #prev-text>
                <unicon width="20" name="angle-right" fill="#A41F02" />
              </template>
              <template #next-text>
                <unicon width="20" name="angle-left" fill="#A41F02" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
  import createServiceCard from "./components/create-service-card";
  import { mapGetters, mapActions } from "vuex";
  import { ValidationObserver } from "vee-validate";
  export default {
    components: {
      createServiceCard,
      ValidationObserver,
    },
    data: () => ({
      VideoCode: "",
    }),
    props: {
      lang: String
    },
    computed: {
      ...mapGetters([
        "servicesPageDto",
        "activeStagesList",
        "stagesFirstPage",
        "stagesPageLength",
        "settings"
      ]),
    },
    created() {
      this.$store.dispatch('app/setLang', !+this.lang ? 1 : 2)
      this.getServiceSettingPage(!+this.lang ? 1 : 2)
      this.fetchSettings().then(() => {
        this.VideoCode = this.settings.videoUrl;
      });
      console.log(this.servicesPageDto);
    },
    methods: {
      ...mapActions([
        "getServiceSettingPage",
        "updateStagesPagination",
        "updateVideoUrl",
        "fetchSettings",
        "setServiceSettings"
      ]),
      submitVideo() {
        this.updateVideoUrl({
            id: this.settings.id,
            videoUrl: this.VideoCode
        })
      },
      setVideo(e) {
        this.VideoCode = e;
      },
      submitSetServiceSettings()
      {
        this.setServiceSettings(this.servicesPageDto);
      }
    },
    watch: {
      lang(lang) {
        this.$store.dispatch('app/setLang', !+lang ? 1 : 2)
        this.getServiceSettingPage(!+lang ? 1 : 2)
      }
    }
  };
</script>
<style lang="scss">
  .video-viewer {
    iframe {
      width: 100%;
    }
  }
</style>
