<template>
  <div>
    <b-card no-body class="overflow-hidden">
      <b-card-body class="px-1 pb-1">
        <b-img
          height="140"
          rounded
          style="object-fit: cover"
          class="w-100"
          :src="
            !item.imageDto.base64
              ? !!item.imageDto.path
                ? item.imageDto.path.replace('~', $store.getters['app/domainHost'])
                : 'https://picsum.photos/600/300/?image=25'
              : item.imageDto.base64
          "
          alt="Image 1"
        ></b-img>
        <div class="output ql-snow">
          <div class="m-0 pt-50 ql-editor p-0" :inner-html.prop="item.description | truncate(50)"></div>
        </div>
      </b-card-body>
      <b-card-footer class="p-0">
        <b-button
          variant="primary"
          block
          squared
          @click="setIsCreateServiceSettingieModal({ is: true, id: item.id })"
          >عرض التفاصيل</b-button
        >
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(["setIsCreateServiceSettingieModal"]),
  },
};
</script>
